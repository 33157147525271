@import '../global.scss';

//TODO

.drawer-root {
	display: 'flex';
} //
.appBar {
	//width: calc(100% - $sidebar-width1); //here be errors
	margin-left: $sidebar-width1;
} //

.drawer {
	width: $sidebar-width1;
	flex-shrink: 0;
	font-size: 16px;
	// @media (max-width: 1366px) {
	// 	width: $sidebar-width-1;
	// }

	@media (max-width: 1430px) {
		width: $sidebar-width-1;
	}
}
.drawerpaper {
	width: $sidebar-width1;
	background-color: $c-black-Sidebar !important;
	border: 0;
	display: 'flex';

	//overflow-y: unset;

	justify-content: space-between !important;
	//height: 99vh !important;
	@media (max-width: 1430px) {
		width: $sidebar-width-1;
	}
}
.parentItem {
	// padding-top: 4;
	// padding-bottom: 4;
	padding-top: 0.1em !important;
	padding-bottom: 0.1em !important;
	margin: 0;
	span {
		font-size: 1em;
	}
}
.childItem {
	padding: 4px 16px !important;
	padding-left: 0px !important;
	span {
		font-size: 0.875em;
		//padding-top: 0;
		//padding-bottom: 0;
		line-height: 1;
	}
}

.icon {
	min-width: 1.6em !important;
	//width: 0.1em;
}
