.comparison-paper-container {
	display: flex;
	background-color: #3e454c !important;
	padding: 4px 8px;
	justify-content: space-evenly;
	align-items: center;
	border-radius: 40px !important;

	min-width: 60px;
	min-height: 24px;


}
