@import 'components/global.scss';

.input {
	position: relative;
}

.textbox {
	display: block;
	position: relative;
	width: 100%;
	color: $c-text;
	font-size: $scale;
	padding: $scale;
	margin: 0 0 $scale1;
	border-radius: $radius;
	font-family: AvenirHeavy, AvenirRoman, serif;
	//background-color: white;
	background-color: white;
	border: 1px solid $c-border;
	-webkit-appearance: none;
	-webkit-border-radius: $radius;

	&:focus {
		background-color: #fafafa;
	}

	&:disabled {
		opacity: 0.5;
	}
}

.success {
	background-size: 0.85em;
	border-color: lighten($c-green, 24%);
	background-position: center right 1em;
}

.successIcon {
	position: absolute;
	top: 50%;
	right: $scale-2;
	margin-top: $scale-1; // label height
	transform: translateY(-50%);
	color: #6179e5 !important;
}

.error {
	position: relative;
	color: $c-red;
	margin-bottom: 0;
	border: 1px solid $c-red;
	background-color: lighten($c-red, 38%);
}

.warning {
	background: darken($c-orange, 3%);
}

// placeholders
::-webkit-input-placeholder {
	color: lighten($c-text, 24%);
}

::-moz-placeholder {
	color: lighten($c-text, 24%);
}

:-ms-input-placeholder {
	color: lighten($c-text, 24%);
}

:-moz-placeholder {
	color: lighten($c-text, 24%);
}

.error::-webkit-input-placeholder {
	color: $c-red;
}

.error::-moz-placeholder {
	color: $c-red;
}

.error:-ms-input-placeholder {
	color: $c-red;
}

.error:-moz-placeholder {
	color: $c-red;
}
