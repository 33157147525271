//@import '../../../../components/global.scss';

.comparison-paper-container-equal {
	display: flex;
	background-color: #f3ae13 !important;
	padding: 8px;
	justify-content: space-evenly;
	align-items: center;
	border-radius: 40px !important;
	min-width: 60px;
	height: 20px;
}
.comparison-paper-container-positive {
	display: flex;
	background-color: #14909b !important;
	padding: 8px;
	justify-content: space-evenly;
	align-items: center;
	border-radius: 40px !important;
	min-width: 60px;
	height: 20px;
}
.comparison-paper-container-negative {
	display: flex;
	background-color: #e56161 !important;
	padding: 8px;
	justify-content: space-evenly;
	align-items: center;
	border-radius: 40px !important;
	min-width: 60px;
	height: 20px;
}
.comparison-paper-item {
	display: flex;
	background-color: transparent !important;
}

.comparison-icon-positive {
	transform: rotate(0deg);
}
.comparison-icon-negative {
	transform: rotate(90deg);
}
.comparison-icon-equal {
	transform: rotate(45deg);
}
