@import '../global.scss';

.large-card-wrapper-root {
	margin-top: 0.9375em;
	background-color: $c-black-Module;
	border-radius: 10px;
}
.linechart-card-wrapper-root {
	margin-top: 0.9375em;
	background-color: $c-black-Module;
	border-radius: 10px;
}
.barchart-card-wrapper-root {
	//margin-top: 40px;
	margin-top: 0.9375em;
	background-color: $c-black-Module;
	border-radius: 10px;
}

.home-card-wrapper-root {
	background-color: transparent;
	padding: $scale7 0;

	height: auto;
	width: 100% !important;
	max-width: 30em;
	> :first-child {
		justify-content: center !important;
		//background-color: $c-black-Sidebar;
		background-color: $c-black-Background;
		border-top-left-radius: 0.7em;
		border-top-right-radius: 0.7em;

		border: 1px solid $c-black-Module;
		width: 100%;
		> div {
			justify-content: center;
		}
	}
	> :last-child > div {
		border-radius: 0%;
		border-bottom-right-radius: 0.7em;
		border-bottom-left-radius: 0.7em;
	}
	// > :last-child > div > div {
	// 	background-color: $c-black-Background;
	// 	border: 2px solid $c-black-Module;
	// }
	> div > div {
		width: 100%;
	}
	form {
		width: 100%;
	}
	button {
		background-color: $c-Teal;
	}
	font-family: AvenirRoman;
}

.content-card-wrapper-root {
	margin-top: 0.9375em;
}

.contentCard-wrapper-root {
	margin-top: 0.9375em;
}
.largeCard-content-root {
	min-height: 15em;
}
.largeCard-content-chart-root {
	min-height: 15em;
	//position: relative;
}
