@import '../global.scss';

.navTabs-root {
	background-color: $c-black-Module;
	position: fixed;
	width: 100%;
	margin-top: 0.25em;
	left: $sidebar-width1;
	min-height: 3em;
	z-index: 50;

	//

	//margin-top: 5.2em; // general
	margin-top: 0.2em; //hardcoded for now before bugfix
	div > div > button > span {
		font-size: 1.6rem;
		font-weight: 800;
		text-transform: capitalize;
		font-style: normal;
		font-family: AvenirHeavy, cursive;
	}
	@media (max-width: 1430px) {
		left: $sidebar-width-1;
	}
	div > span {
		background-color: $c-ElectricBlue;
	}
}

.navTabs-root-Alt {
	width: 100%;
	// min-height: 20px !important;
	// line-height: 1px;

	div {
		//height: 40px;
		> span {
			background-color: transparent;
			//height: 0;
		}
		> div > button {
			//
			padding: 0;
			min-width: 50px !important;
			margin: 0 10px;
			> span > div > :last-child {
				> div {
					font-size: 16px;
				}
			}
		}
	}
}

.navTabs-root-Alt-outlined {
	width: 100%;

	div {
		> span {
			background-color: transparent;

			width: 100%;
		}
		> div > button {
			min-width: 160px !important;
			min-height: 26px;
			border: 1px solid #8b8ba1;
			border-radius: 22px;
			padding: 6px 6px;
			margin-right: 8px;
			> span {
				height: 100%;
				width: 100%;
				> div > div {
					margin-right: 0px !important;
					width: 100%;
				}
			}
		}
		> h4 {
			width: 100%;
			white-space: nowrap;
			font-size: 12px;
		}
	}
}

.navTabs-root-Alt-outlined-selected {
	background-color: #6179e5 !important;
	border-color: #6179e5 !important;
	color: white !important;
}

.navTabs-root-Alt-outlined-disabled {
	background-color: transparent !important;
	border-color: transparent !important;
}
